.login-container {
    background: rgb(4, 30, 112);
    background: linear-gradient(0deg, rgba(4, 30, 112, 1) 0%, rgba(2, 67, 143, 1) 85%);
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contenedorFormulario{
    display: flex;
    justify-content: center;
}

.login-form {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    text-align: center;
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 13vh;
    margin-bottom: 7vh;
}

#renglonLogin{
    max-width: 500px;
    display: flex;
    justify-content: center;
}

#labelLogin{
    color: black;
    padding: 0;
}

#botonInicioSesion{
    margin-left: 0;
}

.botonRegistro{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.botonR{
    background-color: #ffc107;
    color: black!important;
    width: auto;
    padding: 8px 13px 8px 13px;
    border-radius: 7px;
    text-align: center;
    margin-bottom: 3vh;
}

.botonR :hover{
    background-color: #ffca2c;
}

@media (max-width: 560px) {
    .login-container {
        margin-top: 10vh;
    }
}

@media ((min-width: 561px) and (max-width: 769px)) {
    .login-container {
        margin-top: 10vh;
    }
}

@media ((min-width: 769px) and (max-width: 1200px)) {
    
}

@media ((min-width: 1201px) and (max-width: 1500px)) {
    
}