.contenedorNoticias {
    background: rgb(4, 30, 112);
    background: linear-gradient(0deg, rgba(4, 30, 112, 1) 0%, rgba(2, 67, 143, 1) 85%);
    height: auto;
    display: flex;
    justify-content: center;
    padding-top: 5vh;
}

.noticias {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    width: 100%;
    margin: auto;
    padding-top: 2vh;
    background-size: cover;
    text-align: center;
    padding-bottom: 100px;
    color: white
}

.cardsNoticias {
    margin-top: 3vh;
    /* width: 70%; */

}

.carrousel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 40vh;
    margin-bottom: 7vh;
    /* padding: 1vh 0; */

}

.imagenesCarrousel {
    height: 38vh;
    box-shadow: 0 0 10px rgb(255, 255, 255);

}

/* .carrousel .caption-container {
    text-align: center;
    margin-top: 2000px;
    /* Ajusta según sea necesario
}  */

.carousel-item.active{
    display: flex!important;
    justify-content: center;
    margin: 1%;
}

.carousel-indicators{
    margin-bottom: -2rem!important;
}

/* .carrousel .caption-text {
    font-size: 16px;
    /* Ajusta según sea necesario */
    /* color: white; */
    /* Ajusta según sea necesario */
/* } */

.noticias .card-container {
    margin-bottom: 50px;
    border-radius: 15px;
    margin-left: 15%;
    margin-right: 15%;
    display: flex;
    align-items: flex-end;
}

.fechaPublicacion{
    color: #9b9b9b;
    font-style: italic;
}

.noticias .btn {
    margin: 0px;
}

.noticias .card-body {
    display: flex;
    flex-direction: column;
    color: white
}

.card-img-top {
    /* padding-left: 2%; */
    /* padding-right: 2%; */
    /* padding-top: 10px; */
    border-radius: 15px;
    box-shadow: 0 0 10px 0 white;

}

.card-text {
    color: rgb(159, 159, 159)
}

.noticiasContenido {
    background: rgb(225, 225, 225);
    /* background: linear-gradient(0deg, rgba(4, 30, 112, 1) 0%, rgba(2, 67, 143, 1) 85%); */
    height: auto;
    display: flex;
    justify-content: center;
    padding-top: 5vh;
    flex-direction: column;
    color: aliceblue;
}

.tituloContenido {
    display: flex;
    justify-content: flex-start;
    margin-top: 2vh;
    color: #00B6E1;
    /* margin-left: 10%; */
}

.contenido{
    margin-top: 150%;
}

/* .imagenContenido{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 3vh;
    margin-left: 10%;
} */

.contenido figure {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* aspect-ratio: 16/9; */
    box-shadow: 0 0 10px rgba(145, 144, 144, 0.7);
}

.imagenContenido {
    float: right;
    margin: 0 20px;
    width: 50%;
    overflow: hidden;
    margin-bottom: 3vh;
    padding: 10px 10px 10px 10px;
    /* max-height: 30vh; */
    /* overflow: hidden; */
    /* position: relative; Asegura el posicionamiento relativo para el contenido */

}

.imagenContenido img {
    /* width: 100%; Ajusta el ancho de la imagen automáticamente */
    /* height: 100%; Ajusta la altura de la imagen al 100% del contenedor */
    /* object-fit: cover; Ajusta la imagen manteniendo la relación de aspecto */
    border-radius: 15px;
    margin-bottom: 1vh;
    max-height: 50vh;
    overflow: hidden;
    max-width: 100%;
}

@media (max-width: 992px) {
    .imagenContenido {
        float: none;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-left: 15%;
        margin-right: 15%;
        width: auto;
    }
}

@media (max-width: 768px) {
    .noticiasContenido {
        padding-top: 18vh;
        padding-right: 5vw;
        padding-left: 5vw;
    }
}

.noticias .btn {
    background-color: #00B6E1;
    color: black
}

.noticias .btn:hover {
    background-color: #0056b3;
    color: rgb(231, 199, 17);
}

.descripcionNoticia {
    display: flex;
    justify-content: flex-start;
    color: rgb(30, 30, 30);
    /* font-style: italic; */
    /* color: #9b9b9b; */
    /* margin-left: 10%; */
}

.textoNoticia {
    /* margin-left: 10%; */
    margin-right: 1%;
    text-align: justify;
    margin-top: 3%;
    color: rgb(30, 30, 30);

}

.carousel-indicators [data-bs-target]{
    background-color: rgb(30, 30, 30)!important;
}




@media (max-width: 769px) {
    .contenedorNoticias {
        padding-top: 15vh;
    }
}