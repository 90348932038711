header {
    display: flex;
    justify-content: space-between;
    background-color: #dcdcdc;
    padding: 10px;
}

.logo {
    float: left;
    display: flex;
    align-items: center;
    cursor: pointer
}

.titulo {
    margin: 0;
    font-size: 1.5rem;
    font-weight: bold;
    color: rgb(0, 0, 0);
}

.logo img {
    float: left;
    height: 10vh;
    margin-right: 20px;
}

/*MENU*/
content-menu {
    width: 700px;
    float: right;
    position: relative; /* Asegura que z-index funcione */
    z-index: 999;
    
}

content-menu nav {
    text-align: center;
    width: 100%;
    height: 60px;
    
}

.menu {
    list-style: none;
    background-color: #dcdcdc;

    
}

.menu.show {
    z-index: 9999;
}

.menu li {
    float: left;
    margin: 10px 10px;
    padding: 8px 8px;
    color: rgb(0, 0, 0);
    font-size: 20px;
    
}

.menu li a:hover {
    cursor: pointer;
    color: rgb(231, 199, 17);
}

.menu li:hover {
    cursor: pointer;
    color: rgb(231, 199, 17);
}

#linkInscripciones{
    background-color: rgb(231, 199, 17);
    border-radius: 8px;
    padding: 8px 25px 8px 25px;
}

#linkInscripciones:hover{
    background-color: rgb(231, 199, 17);
    color: black;
}

a:link {
    text-decoration: none;
    color: rgb(0, 0, 0);
}

a:visited {
    color: rgb(0, 0, 0);
}

a:active {
    color: white;
}


/*MENU-MOBILE*/


.menu-toggle {
    /* margin-top: 20px; */
    font-size: 40px;
    background: none;
    border: none;
    color: black;
    display: none;
}

@media (max-width: 768px) {

    header {
        top: 0;
        position: fixed;
        width: 100%;
        z-index: 9999;
    }

    .titulo {
        font-size: 1.2rem;
    }

    nav .menu {
        align-items: center;
        background-color: #DCDCDC;
        position: fixed;
        left: 0;
        width: 100%;
        padding: 40px 0;
        height: auto;
        left: 100%;
        transition: left 0.3s;
        z-index: 9999;
        margin-top: 2vh;
    }

    .menu li {
        font-size: 20px;
        width: 100%;
        text-align: center;
    }

    .show {
        left: 0 !important;
        z-index: 9999;
    }

    .menu-toggle {
        display: block;
    }

}

@media (max-width: 1056px) {
    .menu li {
        /* font-size: 0.8em; */
        /* width: 100%; */
        /* text-align: center; */
        padding: 8px 1px;
    }
}

.login-button {
    background-color: #00B6E1;
    /* Color de fondo del botón */
    color: white;
    /* Color del texto */
    padding: 10px 20px;
    /* Relleno interno */
    border: none;
    /* Borde */
    border-radius: 5px;
    /* Bordes redondeados */
    cursor: pointer;
    /* Cambio de cursor al pasar el mouse */
    text-decoration: none;
    /* Elimina la decoración de enlace */
}

/* Estilo al pasar el mouse sobre el botón */
.login-button:hover {
    background-color: #0056b3;
    /* Cambia el color de fondo al pasar el mouse */
}