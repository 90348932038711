.inscripcionForm {
    background: rgb(4, 30, 112);
    background: linear-gradient(0deg, rgba(4, 30, 112, 1) 0%, rgba(2, 67, 143, 1) 85%);
    height: auto;
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.tituloInscripcion {
    border-bottom: solid 5px #00BCD4;
    color: white;
    margin-top: 4vh;
}

.selectTorneosInscripcion {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6vh;
    margin-bottom: 6vh;
    font-size: 1rem;
}

.selectTorneosInscripcion label {
    margin-right: 1vw;
    color: white;

}

.jugadores-container {
    display: flex;
    justify-content: center;
    align-items: center;

}

.datosJugador{
    color: white;
    margin-bottom: 4vh;
}

.datosJugador2{
    color: white;
    margin-bottom: 4vh;
    margin-top:1vh;
    border-top: 0.5px solid gray;
    padding-top: 1vh;
}

.labelJugador1{
    color: white;
    
}

.consultaDniJugador2{
    margin-top: 2vh;
}

.labelConsulta{
    color: white;
}

.form-control{
    height: 27px;
}

#formJugador1{
    border-bottom: 0.5px solid gray;
    padding-bottom: 1vh;
}

.inputJugador1:disabled{
    background-color: #bdbcbc!important;
}

#botonRegistro{
    background-color: #00BCD4;
    color: black;
    margin-top: 2vh;
}

#botonInscribir {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    margin-top: 2vh;
}

#botonInscribir .btn {
    background-color: #FFDF5A;
    color: black;
    margin-bottom: 5vh
}

@media (max-width: 560px) {
    .inscripcionForm {
        /* height: 190vh;
        background-image: url('../Img/fotoFondoMobileNoTexto.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        font-size: 1rem; */
        /* width: auto; */
        margin-top: 13vh;

    }
    
}

@media ((min-width: 561px) and (max-width: 769px)) {
    .inscripcionForm {
        /* height: 100vh;
        background-image: url('../Img/fotoFondoMobileNoTexto.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat; */
        margin-top: 13vh;
    }
}

@media ((min-width: 769px) and (max-width: 1200px)) {
    .jugadores_form {
        /* height: 110vh;
        background-image: url('../Img/fotoFondoMobileNoTexto.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        /* padding-top: 10vh; */
        /* padding-bottom: 30vh */
    }
}

@media ((min-width: 1201px) and (max-width: 1500px)) {
    .jugadores_form {
        /* height: 100vh;
        background-image: url('../Img/fotoFondo1500noTexto.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat; */
    }
}