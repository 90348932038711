/*BANNER*/

.banner-container .banner {
    background: rgb(4, 30, 112);
    background: linear-gradient(0deg, rgba(4, 30, 112, 1) 0%, rgba(2, 67, 143, 1) 85%);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 5px 10px #0005;
    height: auto;
    padding-top: 4vh;
    padding-bottom: 4vh;
}

.textoCircuito {
    display: flex;
    justify-content: center;
    color: white;
    font-family: sans-serif;
    font-size: 4rem;
    text-align: center;
}

.banner-container .banner .content {
    display: flex;
    justify-content: center;
    text-align: center;
}

.banner-container .banner .content h3 {
    color: #fff;
}

.banner-container .banner .col-4 {
    display: flex;
    justify-content: center !important;
}

.contenedorImagenTorneo {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

#columnaContenedorTorneo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#torneo1 {
    display: flex;
    justify-content: center;
    width: 100%
}

.imagenTorneo {
    width: 400px;
    margin-top: 5vh;
    border-radius: 10px;
    box-shadow: 0 0 10px rgb(255, 255, 255);
}

.contenedorInscripciones {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

}

.inscripciones {
    margin-left: 0 !important;
    background-color: #FFDF5A;
    margin-top: 3vh;
}

.btn-outline-dark {
    background-color: #FFDF5A !important;
}

#imgModalContainer {
    display: flex;
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.imgModal {
    border-radius: 10px;
}

@media (max-width:768px) {

    .banner-container {
        height: auto;
    }

}

@media (max-width: 560px) {
    .banner-container .banner {
        margin-top: 10vh;
    }

    .contenedorImagenTorneo {
        display: flex;
        flex-direction: column;
        align-items: center;
        /* margin-top: 2vh; */
        margin-bottom: 10vh;
        width: auto;
    }

    .imagenTorneo {
        width: 350px;
    }

    #imgModalContainer {
        width: 300px;
    }

    .banner-container .banner .content {
        margin-top: 3vh
    }

    .contenedorInscripciones {
        margin-bottom: 2vh;
    }

    .textoCircuito {
        font-size: 3rem;
    }
}

@media ((min-width: 561px) and (max-width: 769px)) {
    .banner-container .banner {
        margin-top: 12vh;
        height: auto;
    }

    .contenedorImagenTorneo {
        margin-bottom: 10vh;
    }

    .contenedorInscripciones {
        margin-bottom: 10vh;
    }

    .textoCircuito {
        font-size: 4rem;
    }

}

@media ((min-width: 769px) and (max-width: 1200px)) {

    .textoCircuito {
        font-size: 5rem;
    }

    .imagenTorneo {
        /* width: 40%; */
        margin-bottom: 7vh;

    }

}

@media ((min-width: 1201px) and (max-width: 1500px)) {


    .textoCircuito {
        font-size: 6rem;
    }

}

/*FIN DE BANNER*/