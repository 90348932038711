.jugadores_form {
    height: auto;
    width: 100%;
    min-height: 77vh;
}

.jugadores_menu {
    /* display: flex;
    justify-content: center;
    align-items: center;
    border-width: 50%; */
    border-bottom: solid 5px #00BCD4;
    color: black;
    margin-top: 10vh;
}

.selectTorneos {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10vh;
    margin-bottom: 10vh;
    font-size: 1.2rem;
}

.selectTorneos label {
    margin-right: 1vw;
    color: black;

}

#tablaInscriptos th{
    /* vertical-align: center; */
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    vertical-align: middle;
}

.thInscriptos{
    background-color: #c3c2c2!important;
}

#botonInscribir {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    /* margin-top: 15vh; */
    /* margin-bottom: 15vh; */
}

#botonInscribir .btn {
    background-color: #FFDF5A;
    color: black;
}

.table{
    text-align: center;
    vertical-align: middle;
}

@media (max-width: 560px) {
    .jugadores_form {
        
        font-size: 1rem;
        /* width: auto; */
        /* margin-top: 10vh; */

    }

    #tablaInscriptos{
        font-size: 0.8rem;
    }

    #tablaInscriptos .btn{
        font-size: 0.8rem;
        width: auto
    }

    .thInscriptos{
        width: auto;
        padding: 0!important;
        padding-left: 0!important
    }
    
}

@media ((min-width: 561px) and (max-width: 769px)) {
    .jugadores_form {
        height: auto;
        
        /* margin-top: 10vh; */
    }
}

@media ((min-width: 769px) and (max-width: 1200px)) {
    .jugadores_form {
        
        /* padding-top: 10vh; */
        /* padding-bottom: 30vh */
    }
}

@media ((min-width: 1201px) and (max-width: 1500px)) {
    
}